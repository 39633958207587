import { useEffect, useRef, useState } from 'react';

export function Main(): JSX.Element {
  const [profile, setProfile] = useState({} as Record<string, any> | null);

  useEffect(() => {
    fetch('api/profile')
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response.json();
      })
      .then(rewards => setProfile(rewards))
      .catch(console.error);
  }, []);

  const login = !profile?.name ? (
    <a href='api/login/twitch'>Login</a>
  ) : (
    <a href='api/logout'>Logout</a>
  );

  const [rewards, setRewards] = useState<string[]>([]);

  useEffect(() => {
    fetch('api/profile/rewards/charity')
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response.json();
      })
      .then(rewards => setRewards(rewards))
      .catch(console.error);
  }, []);

  const rewardsTodo = [
    ['autogramm', 'jackpot'].some(str =>
      rewards.find(reward => reward.toLowerCase().includes(str))
    ) ? (
      <p>
        Für deine Autogrammkarte melde dich bitte{' '}
        <a href='https://forms.gle/ALeFEYRm6xEZBjMB6' target='_blank'>
          hier
        </a>
        .
      </p>
    ) : null,
    ['battlepass', 'jackpot'].some(str =>
      rewards.find(reward => reward.toLowerCase().includes(str))
    ) ? (
      <p>
        Für deinen Battlepass melde dich bitte auf dem{' '}
        <a href='https://discord.gg/heystan' target='_blank'>
          Discord Server
        </a>{' '}
        im Kanal{' '}
        <a
          href='https://discord.com/channels/313003647699124237/686018677450997820'
          target='_blank'>
          #🚨│support
        </a>{' '}
        und eröffne dort ein Twitch-Support-Ticket. .
      </p>
    ) : null,
    ['friendlist', 'freundesliste', 'skinwunsch', 'skin wunsch'].some(str =>
      rewards.find(reward => reward.toLowerCase().includes(str))
    ) ? (
      <p>
        Für weitere Angaben (z.B. Freundesliste, Skinwunsch) melde dich bitte{' '}
        <a href='api/profile/rewards/notes' target='_blank'>
          hier
        </a>
        .
      </p>
    ) : null,
  ].filter(Boolean);

  const ref_rewards = useRef<null | HTMLDivElement>(null);
  const ref_streamplan = useRef<null | HTMLDivElement>(null);
  const ref_faq = useRef<null | HTMLDivElement>(null);

  return (
    <>
      <main className='main-main container' ref={ref_streamplan}>
        <h2>Streamplan</h2>
        <img
          style={{ borderRadius: '1rem', width: '100%' }}
          src={'/img/streamplan.png?' + new Date().getTime()}
          alt='Streamplan'
        />
      </main>

      <main className='main-main container' ref={ref_streamplan}>
        <h2>Twitch</h2>
        <iframe
          style={{ aspectRatio: '16 / 9', borderRadius: '0.75rem', width: '100%' }}
          src='https://player.twitch.tv/?channel=heystan&autoplay=true&muted=true&parent=heystan.de'
          frameBorder='0'
          allowFullScreen></iframe>
      </main>

      <main className='main-main container' ref={ref_rewards}>
        <h2>
          Belohnungen{rewards.length > 0 && ` (${rewards.length})`}
          <span style={{ fontSize: 'smaller', float: 'right' }}>{login}</span>
        </h2>

        {rewardsTodo.length > 0 && <div className='main-rewards-todo'>{rewardsTodo}</div>}

        <div className='main-rewards'>
          {rewards.length > 0 ? (
            rewards
              // .filter((reward, index, self) => self.indexOf(reward) === index)
              // .map(reward => `${rewards.filter(r => r === reward).length}x ${reward}`)
              .map(reward => <div className='main-reward'>{reward}</div>)
          ) : (
            <i>Du hast noch keine Belohnungen gewonnen.</i>
          )}
        </div>
      </main>

      <main className='main-faq container' ref={ref_faq}>
        <h2>FAQ</h2>

        <details>
          <summary>Wie bekomme ich eine Belohnung?</summary>
          <p>
            Der Subathon verlängert sich mit den folgenden Aktionen: Creator Code "HeyStani" in
            Fortnite, Geschenk (
            <a href='https://tiktok.com/@realstani' target='_blank'>
              TikTok
            </a>
            ), Spende (
            <a href='https://www.tipeeestream.com/heystan/donation' target='_blank'>
              Tipeeestream
            </a>
            ), Einkauf (
            <a href='https://teespring.com/de/stores/stan-merch' target='_blank'>
              Teespring
            </a>
            ), Bits, Hypechat, Subscription (
            <a href='https://twitch.tv/heystan' target='_blank'>
              Twitch
            </a>
            ), Mitgliedschaft, Superchat (
            <a href='https://youtube.com/c/Stangespielt' target='_blank'>
              YouTube
            </a>
            ). Ab einer Wertigkeit von etwa 4 EUR pro Aktion bekommst du eine Belohnung.
          </p>
        </details>

        <details>
          <summary>Wo muss ich mich melden wenn ich etwas gewonnen habe?</summary>
          <p>
            Wenn du dich bei uns melden musst, siehst du das unter der Liste deiner Belohnungen.
            Hast du Fragen, melde dich ebenfalls bei den Moderatoren auf{' '}
            <a href='https://discord.gg/heystan' target='_blank'>
              Discord
            </a>
            .
          </p>
        </details>

        <details>
          <summary>Welche Belohnungen gibt es?</summary>
          <p>Die Belohnungen können von Zeit zu Zeit variieren. Folgendes kannst du gewinnen:</p>
          <ul>
            <li>
              <span>Autogramm</span> Du gewinnst die neue Autogrammkarte von Stan. Das Motiv der
              Autogrammkarte ändert sich immer mal. Lass dir also keine Auflage entgehen!
            </li>
            <li>
              <span>Battlepass</span> Du gewinnst eine 1.000 V-Bucks Karte. Mit dieser kannst du dir
              den Battlepass freischalten. Solltest du den Battlepass oder Fortnite Crew bereits
              besitzen, kannst du selbstständig entscheiden was du mit den V-Bucks machst.
            </li>
            <li>
              <span>Dab</span> Stan dabt für dich.
            </li>
            <li>
              <span>Freundesliste</span> Stan nimmt dich in seine Freundesliste in Fortnite auf.
            </li>
            <li>
              <span>Gruß</span> Du wirst namentlich von Stan erwähnt, fühl dich geehrt.
            </li>
            <li>
              <span>Jackpot</span> Du gewinnst die Autogramkarte, den Battlepass und den VIP-Status.
            </li>
            <li>
              <span>Minuten</span> Mit dem Gewinn von 1-5 Minuten erhöhst du den Timer im Stream.
            </li>
            <li>
              <span>Nichts</span> Du gewinnst nichts, vielleicht klappt es ja beim nächsten Mal.
            </li>
            <li>
              <span>Skinwunsch</span> Du darfst dir einen Skin wünschen, den Stan dann im Stream für
              dich spielt.
            </li>
            <li>
              <span>Sport</span> Stan muss Sport für dich machen.
            </li>
            {/* <li>
              <span>Urkunde</span> Du gewinnst die Urkunde für die Teilnahme am Subathon. Das Motiv
              der Urkunde ändert sich immer mal. Lass dir also keine Auflage entgehen! Urkunden gibt
              es sowohl digital, als auch per Post in begrenzter Anzahl.
            </li> */}
            <li>
              <span>Videoerwähnung</span> Du bekommst in einem der nächsten Videos eine Erwähnung.
            </li>
            <li>
              <span>VIP</span> Du bekommst den VIP-Status auf Twitch für eine begrenzte Zeit. Mit
              dem Status hast du nicht nur ein neues cooles Abzeichen, sondern du kannst auch im
              Chat schreiben wenn der Abonnenten-/ Follower-/ Langsame-Modus aktiv ist.
            </li>
          </ul>
        </details>

        <details>
          <summary>Kann mein Gewinn verfallen?</summary>
          <p>
            Ja, wenn du dich nicht innerhalb von 7 Tagen nach dem Streamende bei den Moderatoren
            meldest oder sich der Name von deinem verknüpften Konto ändert, verfallen deine Gewinne.
          </p>
        </details>

        <details>
          <summary>Was gibt es noch zu beachten?</summary>
          <p>
            Das ganze Communityprojekt funktioniert nur durch die Mithilfe vieler freiwilliger
            Helfer. Sollten wir etwas nicht richtig mitbekommen, uns ein Fehler unterlaufen oder
            sonstige Komplikationen entstehen, möchten wir uns dafür entschuldigen. Wir sind nur
            Menschen und versuchen unser Bestes. Der Rechtsweg ist ausgeschlossen. Gewinne können
            pro Kategorie nur 1x eingelöst werden. Verwende bei Spenden deinen Twitch-Namen, damit
            wir dich zuordnen können.
          </p>
        </details>
      </main>
    </>
  );
}
