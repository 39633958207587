export function Impressum() {
  return (
    <main className='container'>
      <h1>Impressum</h1>

      <h2>INSIGHT GG GMBH</h2>

      <p>
        Müllerstraße 12A
        <br />
        86153 Augsburg
        <br />
        Deutschland
      </p>

      <p>
        E-Mail: info[at]ins.gg
        <br />
        Internet:{' '}
        <a href='https://ins.gg/' target='_blank'>
          ins.gg
        </a>
      </p>

      <p>
        Registergericht: AG Augsburg
        <br />
        Registernummer: 32838
        <br />
        USt-IdNr.: DE319888766
      </p>

      <p>Vertretungsberechtigter Geschäftsführer: Peter Alexander Oettel</p>

      <p>
        <b>heystan.de</b> ist Teil von <b>HeyStan Foto- & Videografie</b> unter dem Management von{' '}
        <b>INSIGHT GG GMBH</b>.
      </p>

      <h1>Datenschutzerklärung</h1>

      <h2>1. Datenschutz auf einen Blick</h2>

      <h3>Allgemeine Hinweise</h3>

      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
        personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten
        sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche
        Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>

      <h3>Datenerfassung auf dieser Website</h3>

      <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>

      <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.</p>

      <h4>Wie erfassen wir Ihre Daten?</h4>

      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es
        sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
      </p>

      <p>
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch
        unsere ITSysteme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser,
        Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
        automatisch, sobald Sie diese Website betreten.
      </p>

      <h4>Wofür nutzen wir Ihre Daten?</h4>

      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
        gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden
      </p>

      <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>

      <p>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck
        Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die
        Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
        Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft
        widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der
        Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie zu weiteren Fragen zum
        Thema Datenschutz können Sie sich jederzeit an uns wenden.
      </p>

      <h3>Analyse-Tools und Tools von Drittanbietern</h3>

      <p>
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das
        geschieht vor allem mit sogenannten Analyseprogrammen.
      </p>

      <p>
        Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
        Datenschutzerklärung.
      </p>

      <h2>2. Hosting</h2>

      <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>

      <h3>Contabo</h3>

      <p>
        Anbieter ist die Contabo GmbH, Aschauer Straße 32a, 81549 München (nachfolgend „Contabo“).
        Wenn Sie unsere Website besuchen, erfasst Contabo verschiedene Logfiles inklusive Ihrer
        IP-Adressen.
      </p>

      <p>
        Weitere Informationen entnehmen Sie der Datenschutzerklärung von Contabo:{' '}
        <a href='https://contabo.com/de/legal/privacy' target='_blank' rel='noreferrer'>
          contabo.com/de/legal/privacy
        </a>
      </p>

      <p>
        Die Verwendung von Contabo erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben
        ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website.
        Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
        ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit
        die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät
        des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist
        jederzeit widerrufbar.
      </p>

      <h3>Auftragsverarbeitung</h3>

      <p>
        Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten
        Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen
        Vertrag, dergewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher
        nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>

      <h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>

      <h3>Datenschutz</h3>

      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
        behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen
        Datenschutzvorschriften sowie dieser Datenschutzerklärung.
      </p>

      <p>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
        Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die
        vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie
        nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>

      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation
        per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
        Zugriff durch Dritte ist nicht möglich.
      </p>

      <h3>Hinweis zur verantwortlichen Stelle</h3>

      <p>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam
        mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B.
        Namen, E-Mail-Adressen o. Ä.) entscheidet.
      </p>

      <h3>Speicherdauer</h3>

      <p>
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde,
        verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung
        entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
        Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich
        zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer-
        oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung
        nach Fortfall dieser Gründe.
      </p>

      <h3>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h3>

      <p>
        Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre
        personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit.
        a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im
        Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in
        Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a
        DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in
        Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die
        Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist
        jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung
        vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6
        Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung
        einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c
        DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach
        Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
        Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.
      </p>

      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>

      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie
        können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis
        zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>

      <h3>
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung
        (Art. 21 DSGVO)
      </h3>

      <p>
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT,
        HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN,
        GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH
        FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF
        DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE
        WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
        VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG
        DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH
        ART. 21 ABS. 1 DSGVO).
      </p>

      <p>
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE
        DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER
        DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES
        MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
        PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
        (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
      </p>

      <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>

      <p>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei
        einerAufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts,
        ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
        besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
      </p>

      <h3>Recht auf Datenübertragbarkeit</h3>

      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
        Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen,
        maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
        an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
        ist.
      </p>

      <h3>Auskunft, Löschung und Berichtigung</h3>

      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
        unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und
        Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
        Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten
        können Sie sich jederzeit an uns wenden.
      </p>

      <h3>Recht auf Einschränkung der Verarbeitung</h3>

      <p>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der
        Verarbeitung besteht in folgenden Fällen:
      </p>

      <p>
        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
        benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
        das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
      </p>

      <p>
        Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können
        Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
      </p>

      <p>
        Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
        Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt
        der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
      </p>

      <p>
        Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung
        zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht,
        wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen.
      </p>

      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese
        Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
        einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen
        öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
      </p>

      <h3>SSL- bzw. TLS-Verschlüsselung</h3>

      <p>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
        Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
        senden, eine SSL- bzw. TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
        daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem
        Schloss-Symbol in Ihrer Browserzeile.
      </p>

      <p>
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
        übermitteln, nicht von Dritten mitgelesen werden.
      </p>

      <h3>Widerspruch gegen Werbe-E-Mails</h3>

      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur
        Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird
        hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
        Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor.
      </p>

      <h2>4. Datenerfassung auf dieser Website</h2>

      <h3>Cookies</h3>

      <p>
        Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und
        richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die
        Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät
        gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente
        Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine
        automatische Löschung durch Ihren Webbrowser erfolgt.
      </p>

      <p>
        Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog.
        Third-Party-Cookies). Third-Party-Cookies ermöglichen die Einbindung bestimmter
        Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z. B. Cookies zur Abwicklung
        von Zahlungsdienstleistungen).
      </p>

      <p>
        Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da
        bestimmte Webseitenfunktionen ohne diese nicht funktionieren würden (z. B. die
        Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies können zur Auswertung des
        Nutzerverhaltens oder zu Werbezwecken verwendet werden.
      </p>

      <p>
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung
        bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur
        Optimierung der Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind
        (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert,
        sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
        berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien
        und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von
        Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die
        Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO
        und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.
      </p>

      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert
        werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle
        oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des
        Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser
        Website eingeschränkt sein.
      </p>

      <p>
        Welche Cookies und Dienste auf dieser Website eingesetzt werden, können Sie dieser
        Datenschutzerklärung entnehmen.
      </p>

      <h3>Server-Log-Dateien</h3>

      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
        Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
      </p>

      <p>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>IP-Adresse</li>
      </p>

      <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>

      <p>
        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung
        und der Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst werden.
      </p>

      <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>

      <p>
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive
        aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der
        Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht
        ohne Ihre Einwilligung weiter.
      </p>

      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern
        Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung
        vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
        auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten
        Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
        DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
      </p>

      <p>
        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns
        zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
        Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens).
        Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben
        unberührt.
      </p>

      <p id='quelle'>
        <i>
          Quelle:{' '}
          <a href='https://e-recht24.de' target='_blank' rel='noreferrer'>
            e-recht24.de
          </a>
        </i>
      </p>
    </main>
  );
}
