import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => navigate('/'), 3 * 1000);
    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <main className='container'>
      <h2 style={{ textAlign: 'center' }}>404</h2>
      <p style={{ textAlign: 'center' }}>Hier wachsen noch keine Tomaten</p>
    </main>
  );
}
