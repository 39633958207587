import { ThemeProvider, createTheme } from '@mui/material';
import { useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, NavLink, Route, Routes } from 'react-router-dom';

import './index.css';

import { Impressum } from './pages/Impressum';
import { Main } from './pages/Main';
import { NotFound } from './pages/NotFound';

createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
    <App>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/impressum' element={<Impressum />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </App>
  </BrowserRouter>
);

function App({ children }: { children: React.ReactNode }): JSX.Element {
  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        light: '#53abff',
        main: '#2997FF',
        dark: '#1c69b2',
        contrastText: '#f5f5f7',
      },
      secondary: {
        light: '#ffa653',
        main: '#ff9029',
        dark: '#b2641c',
        contrastText: '#1d1d1f',
      },
    },
  });

  const links = [
    { icon: 'fa-brands fa-discord', url: 'https://discord.gg/heystan' },
    { icon: 'fa-brands fa-instagram', url: 'https://instagram.com/Hey_Stani' },
    { icon: 'fa-brands fa-tiktok', url: 'https://tiktok.com/@realstani' },
    { icon: 'fa-brands fa-twitch', url: 'https://twitch.tv/heystan' },
    { icon: 'fa-brands fa-twitter', url: 'https://twitter.com/HeyStani' },
    { icon: 'fa-brands fa-whatsapp', url: 'https://whatsapp.com/channel/0029VaDbF3S4o7qNfA8CLg09' },
    { icon: 'fa-brands fa-youtube', url: 'https://youtube.com/c/Stangespielt' },
    { icon: 'fa-solid fa-gavel', url: 'https://forms.gle/JTvY3DFG4CwwnFAe8' },
  ];

  const ref_header = useRef<null | HTMLDivElement>(null);

  return (
    <ThemeProvider theme={theme}>
      <header className='main-header container' ref={ref_header}>
        <h1>HeyStan.de</h1>

        <nav className='main-nav'>
          {links.map((link, index) => (
            <a key={index} href={link.url} target='_blank'>
              <i className={link.icon} />
            </a>
          ))}
        </nav>
      </header>

      {children}

      <footer className='main-footer'>
        <NavLink to='/impressum'>Impressum & Datenschutz</NavLink>
      </footer>

      {/* <a
        className='main-reward'
        style={{
          background: 'hwb(240 11% 99%)',
          border: '3px solid #2997ff',
          position: 'fixed',
          bottom: '2rem',
          right: '2rem',
        }}
        onClick={() => ref_header.current?.scrollIntoView({ behavior: 'smooth' })}>
        <i className='fa-solid fa-arrow-up'></i>
      </a> */}
    </ThemeProvider>
  );
}
